const initState = {
    linkedIn: {},
    linkedInResponse: null,
    
}

const LinkedInReducer = (state = initState, action) => {
    if (action.type === 'GET_LINKEDIN') {
        return {
            ...state,
            linkedIn: action.linkedIn,
            linkedInResponse:action.linkedInResponse
        }
    }
    // /// THESE ARE GENERAL APPLICABLE TO ALL API's
    else if (action.type === 'LINKEDIN_RESET') {
        return {
            ...state,
            
            linkedInResponse: null,
            
        }
    }
    else if (action.type === 'LINKEDIN_SUCCESS') {
        return {
            ...state,
            
            linkedInResponse: action.linkedInResponse,
        }
    }
    else if (action.type === 'LINKEDIN_FAIL') {
        return {
            ...state,
            
            linkedInResponse: action.linkedInResponse,
        }
    }

    return state;
}
export default LinkedInReducer;