import React from "react";
import "../Components/Footer/Footer.css";
import "./Slider.css";
import { getAllJobsDashboard } from "../actions/dashboardAction";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import LocationIcon from "../Assests/Location.svg";

import "../App.css";

function AllJobSLider(props) {
  useEffect(() => {
    loadGetHomeJob();
  }, []);
  const loadGetHomeJob = async () => {
    await props.getAllJobsDashboard();
    return null;
  };

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 22;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  return (
    <>
      <div className="row mx-3 mt-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 shadow" style={{ borderRadius: "10px" }}>
              <h4 className="pited-job-main mt-3">All Jobs</h4>
              <div className="row mx-0">
                {props.dashboardReducer.dashboardAllJobs &&
                props.dashboardReducer.dashboardAllJobs.length >= 0
                  ? props.dashboardReducer.dashboardAllJobs
                      .slice(0, 6)
                      .map((job) => (
                        <>
                          <div
                            className="col-lg-4 mt-3 hover shadow mt-2 mr-2 pt-2 something-dash-here d-lg-block d-md-none d-none"
                            style={{ borderRadius: "10px" }}
                          >
                            <Link
                              to={`/job-details?id=${job.id}`}
                              className="link-tag-home"
                            >
                              <div>
                                <h6 className="m-0 p-0 head-color-of-posr">
                                  {job.job_title !== null &&
                                  job.job_title !== undefined
                                    ? text_truncate(job.job_title)
                                    : ""}
                                </h6>
                                <p className="technsym-text-comp">
                                  {job.rec !== null && job.rec !== undefined
                                    ? job.rec
                                    : ""}
                                </p>

                                <p
                                  className="text-dark mb-2"
                                  style={{
                                    fontSize: "12px",
                                    color: "#707070",
                                  }}
                                >
                                  <img
                                    className="mt-n1 mr-1"
                                    height="12px"
                                    src={LocationIcon}
                                    alt=""
                                  />

                                  {job.country !== null &&
                                  job.country !== undefined &&
                                  job.country !== ""
                                    ? job.country + ", "
                                    : ""}
                                  {job.state !== null &&
                                  job.state !== undefined &&
                                  job.state !== ""
                                    ? job.state + ", "
                                    : ""}
                                  {job.city !== null &&
                                  job.city !== undefined &&
                                  job.city !== ""
                                    ? job.city
                                    : ""}
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Tablet */}
                          <div
                            className="col-md-6 mt-3 shadow mt-2 mr-2 pt-2 something-tab d-lg-none d-md-block d-none"
                            style={{ borderRadius: "10px" }}
                          >
                            <Link
                              to={`/job-details?id=${job.id}`}
                              className="link-tag-home"
                            >
                              <div>
                                <h6 className="m-0 p-0 head-color-of-posr">
                                  {job.title !== null && job.title !== undefined
                                    ? text_truncate(job.job_title)
                                    : ""}
                                </h6>
                                <p className="technsym-text-comp">
                                  {job.rec !== null && job.rec !== undefined
                                    ? job.rec
                                    : ""}
                                </p>

                                <p
                                  className="text-dark mb-2"
                                  style={{
                                    fontSize: "12px",
                                    color: "#707070",
                                  }}
                                >
                                  <img
                                    className="mt-n1 mr-1"
                                    height="12px"
                                    src={LocationIcon}
                                    alt=""
                                  />
                                  {job.country !== null &&
                                  job.country !== undefined &&
                                  job.country !== ""
                                    ? job.country + ", "
                                    : ""}
                                  {job.state !== null &&
                                  job.state !== undefined &&
                                  job.state !== ""
                                    ? job.state + ", "
                                    : ""}
                                  {job.city !== null &&
                                  job.city !== undefined &&
                                  job.city !== ""
                                    ? job.city
                                    : ""}
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Mobile */}
                          <div
                            className="col-12 mt-3 shadow mt-2 pt-2 d-lg-none d-md-none d-block"
                            style={{ borderRadius: "10px" }}
                          >
                            <Link
                              to={`/job-details?id=${job.id}`}
                              className="link-tag-home"
                            >
                              <div>
                                <h6 className="m-0 p-0 head-color-of-posr">
                                  {job.job_title !== null &&
                                  job.job_title !== undefined
                                    ? text_truncate(job.job_title)
                                    : ""}
                                </h6>
                                <p className="technsym-text-comp">
                                  {job.rec !== null && job.rec !== undefined
                                    ? job.rec
                                    : ""}
                                </p>

                                <p
                                  className="text-dark mb-2"
                                  style={{
                                    fontSize: "12px",
                                    color: "#707070",
                                  }}
                                >
                                  <img
                                    className="mt-n1 mr-1"
                                    height="12px"
                                    src={LocationIcon}
                                    alt=""
                                  />
                                  {job.country !== null &&
                                  job.country !== undefined &&
                                  job.country !== ""
                                    ? job.country + ", "
                                    : ""}
                                  {job.state !== null &&
                                  job.state !== undefined &&
                                  job.state !== ""
                                    ? job.state + ", "
                                    : ""}
                                  {job.city !== null &&
                                  job.city !== undefined &&
                                  job.city !== ""
                                    ? job.city
                                    : ""}
                                </p>
                              </div>
                            </Link>
                          </div>
                        </>
                      ))
                  : "No jobs available!"}
              </div>

              <div className="row mt-3">
                <div className="col-md-12">
                  <Link to="/viewalljobs" className="link-tag-home">
                    <p className="view-more-pofts-jkbs">View more</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboardReducer: state.dashboardReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllJobsDashboard: () =>
    dispatch(getAllJobsDashboard(localStorage.getItem("auth_id"))),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllJobSLider);
