import React from "react";
import "./GetPremium.css";
import "bootstrap/dist/css/bootstrap.min.css";
import OkIcon from "../../Assests/ok-icon.svg";
import WrongIcon from "../../Assests/wrong-icon.svg";
import { getPricing } from "../../actions/pricingAction";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Nav2 from "../../Components/Nav2/Nav2";
import Footer2 from "../../Components/Footer/Footer2";
import MStripe from "../../stripeBtn";
import { Link } from "react-router-dom";
// import pre from "../../Assests/promo.png";
import PremiumBanner from "../../Components/PremiumBanner";
import Navbar from "../../Components/Navbar/Navbar";
import { LoginModalAction } from "../../actions/loginModalAction";
import GPay from "../../Components/GooglePay/Gpay";

function GetPremium(props) {
  useEffect(() => {
    loadGetPersonal(localStorage.getItem("auth_id"));
  }, []);
  const loadGetPersonal = async (userId) => {
    await props.getPricing(userId);
    return null;
  };

  return (
    <>
      {localStorage.getItem("auth_id") ? <Nav2 /> : <Navbar />}
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-5">
            <h1
              className="font-weight-bold text-center"
              style={{ color: "#865ddd" }}
            >
              Plans and Pricing
            </h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <PremiumBanner />
          </div>
          <div className="col-md-2"></div>
        </div>
        {/* premium Starts */}
        {/* {props.pricingReducer.pricing.packages &&
        props.pricingReducer.pricing.packages.length > 0 ? (
          props.pricingReducer.pricing.packages.map((pri) => ( */}
        <div className="row">
          <div
            className="col-lg-5 col-sm-12 shadow p-3 mb-5"
            style={{ borderRadius: "15px", margin: "0 auto" }}
          >
            <h3 className="prem-div-head font-weight-bold text-center">
              Basic Package
            </h3>
            <div className="row mt-5">
              <div className="col-2">
                <div className="text-center">
                  <img src={OkIcon} className="w-0" />
                </div>
              </div>
              <div className="col-10">
                <p className="stand-text">Apply to multiple jobs</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2">
                <div className="text-center">
                  <img src={OkIcon} className="w-0" />
                </div>
              </div>
              <div className="col-10">
                <p className="stand-text">
                  See who has viewed and shortlisted your profile{" "}
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2">
                <div className="text-center">
                  <img src={OkIcon} className="w-0" />
                </div>
              </div>
              <div className="col-10">
                <p className="stand-text">
                  Communicate with potential employer
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2">
                <div className="text-center">
                  <img src={OkIcon} className="w-0" />
                </div>
              </div>
              <div className="col-10">
                <p className="stand-text">Monitor job application status</p>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-12" style={{ justifyContent: "center" }}>
                <div className="style-4">
                  <h1 className="cash-stan-main">
                    {/* {localStorage.getItem("userCurrentLocation")
                          ? localStorage.getItem("userCurrentLocation") ==
                            "PK"
                            ? "PKR " + pri.price_pkr
                            : "USD " + pri.price
                          : !pri.is_show_usd
                          ? "PKR " + pri.price_pkr
                          : "USD " + pri.price} */}
                    PKR 500
                    <small className="small-month">/month</small>
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center mt-4">
                <p style={{ color: "red" }}>
                  <b>Special launch offer for 90 days</b>
                </p>
              </div>
            </div>
            {/* <div className="row">
                  <div className="col-md-12 text-center mt-4">
                    {!localStorage.getItem("auth_id") ? (
                      <button
                        onClick={() => {
                          props.LoginModalAction(true);
                        }}
                        style={{
                          background: "orange",
                          padding: "0px 12px",
                          height: "30px",
                          lineHeight: "30px",
                          color: "rgb(255, 255, 255)",
                          fontWeight: "bold",
                          boxShadow: "rgb(255 255 255 / 25%) 0px 1px 0px inset",
                          textShadow: "rgb(0 0 0 / 25%) 0px -1px 0px",
                          borderRadius: "4px",
                          minWidth: "100px",
                          border: "none",
                        }}
                      >
                        Pay With Card
                      </button>
                    ) : (
                      <>
                      {
                        !localStorage.getItem("userCurrentLocation") == "Pakistan" ? 
                        <MStripe id={pri.id} amount={!localStorage.getItem("userCurrentLocation") == "Pakistan"
                        ? pri.price
                        : pri.price_pkr} />
                        : 
                        <GPay totalPrice={!localStorage.getItem("userCurrentLocation") == "Pakistan"
                        ? pri.price
                        : pri.price_pkr} currencyCode={!localStorage.getItem("userCurrentLocation") == "Pakistan"
                        ? "USD"
                        : "PKR"}/>
                      }
                      </>
                    )}
                  </div>
                </div> */}
          </div>
        </div>
        {/* ))
        ) : (
          <div>
            <div className="spinner-border text-secondary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )} */}

        {/* premium Ends */}
      </div>
      <Footer2 />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pricingReducer: state.pricingReducer,
  loginModalReducer: state.loginModalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPricing: (userId) => dispatch(getPricing(userId)),
  LoginModalAction: (toggle) => dispatch(LoginModalAction(toggle)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GetPremium);
