export const LoginModalAction = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_MODAL_TOGGLE",
      loginModal: toggle,
    });
  };
};
export const RegisterModalAction = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_MODAL_TOGGLE",
      registerModal: toggle,
    });
  };
};
