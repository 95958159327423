import firebase from ".././helpers/firebase";

export const SignIn = (username, password) => {
  return (dispatch) => {
    dispatch({
      type: "RESET_AUTH_MESSAGE",
      loading: false,
    });
    fetch(`${process.env.REACT_APP_API_END_POINT}/user/login.php`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        device_token: localStorage.getItem("dToken1"),
        username: username,
        password: password,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.data.is_verified == 0 && response.status === 404) {
          alert(response.message);
          window.location = `/otp?auth=${response.data.auth_id}`;
          dispatch({
            loading: true,
          });
          return;
        } else if (
          Object.keys(response.data).length === 0 &&
          response.status != 200
        ) {
          alert(response.message);
          dispatch({
            loading: true,
          });
          return;
        } else if (
          Object.keys(response.data).length > 0 &&
          response.status === 200
        ) {
          const auth_id = response.data.auth_id;
          const empId = response.data.id;
          const premium = response.data.premium;
          const first_name = response.data.first_name;
          const isFirstLogin = response.data.first_time_login;
          dispatch({
            type: "SIGN_IN",
            authError: null,
            auth_id,
            employee_id: empId,
            authMessage: "Signing you in...",
            loading: true,
          });
          localStorage.setItem("auth_id", auth_id);
          localStorage.setItem("premium", premium);
          localStorage.setItem("is_verified", response.data.is_verified);
          localStorage.setItem("first_name", first_name);
          localStorage.setItem("isFirstLogin", isFirstLogin);

          window.location = "/dashboard";
        }
      })
      .catch((error) => {
        const authError = JSON.stringify(error);
        dispatch({
          type: "SIGN_IN",
          authError,
          auth_id: null,
          employee_id: null,
          authMessage: null,
          loading: true,
        });
      });
  };
};

// export const signUp = (credentials) => {
//     const { userName, password } = credentials;
//     return (dispatch) => {
//         /// reset any auth messages
//         dispatch({
//             type: "RESET_AUTH_MESSAGE",
//         });

//         /// post request
//         Axios.get(`${process.env.REACT_APP_API_END_POINT}/api/user/signup`, {
//             "userName": userName,
//             "password": password
//         }).then((response) => {
//             const authToken = response.data["token"];
//             dispatch({
//                 type: "SIGN_UP",
//                 authError: null,
//                 authToken,
//                 authMessage: "Signing you up..."
//             });
//         }).catch((error) => {
//             const authError = JSON.stringify(error)
//             dispatch({
//                 type: "SIGN_UP",
//                 authError,
//                 authToken: null,
//                 authMessage: "Error occurred in signing up!"
//             });
//         })
//     }
// }

export const signOut = (props) => {
  localStorage.removeItem("auth_id");
  localStorage.removeItem("premium");
  localStorage.removeItem("first_name");
  localStorage.removeItem("persist:root");
  localStorage.removeItem("message");
  localStorage.removeItem("language");
  localStorage.removeItem("skill");
  localStorage.removeItem("dToken1");
  //  props.history.push("/login")
  window.location = "/home";
  // window.open("/")
  return {
    type: "SIGN_OUT",
  };
};

// export const resetAuthMessage = () => {
//     return {
//         type: "RESET_AUTH_MESSAGE",
//     };
// };

export const facebookLogin = () => {
  return (dispatch) => {
    var provider = new firebase.auth.FacebookAuthProvider();
    // provider.setCustomParameters()
    // provider.setCustomParameters()
    provider.addScope("email");

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        console.log("ppppp", result);
        var token = result.credential.accessToken;
        var userEmail = result.additionalUserInfo.profile.email;
        var user = result.user;
        var first = user.displayName.split(" ")[0];
        var last = user.displayName.split(" ")[1];
        console.log(
          "ffff",
          user,
          user.l,
          user.displayName,
          userEmail,
          first,
          last
        );

        var data = {
          type: 2,
          email: userEmail,
          first_name: first,
          last_name: last,
          num: "000000",
          token: user.l,
          device_token: localStorage.getItem("dToken1"),
        };
        console.log("gggggggg", data);

        fetch(`${process.env.REACT_APP_API_END_POINT}/user/social_login.php`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            type: 2,
            email: userEmail,
            first_name: first,
            last_name: last,
            num: "000000",
            token: user.l,
            device_token: localStorage.getItem("dToken1"),
          }),
        })
          .then((res) => {
            console.log("res aqib", res);
            if (res.status !== 200) {
              alert("Email or password is incorrect");
            }
            return res.json();
          })
          .then((response) => {
            console.log("data", response);
            const auth_id = response.data.auth_id;

            if (auth_id !== "" && auth_id !== null && auth_id !== undefined) {
              window.location = "/dashboard";
            } else {
              alert("Email or password is incorrect");
            }
            localStorage.setItem("auth_id", auth_id);
            localStorage.setItem("first_name", first);
            // window.location.reload(false)
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
        console.log("ffff", errorMessage);
      });
  };
};

export const googleLogin = () => {
  return (dispatch) => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("email");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        console.log("ppppp", result);
        var token = result.credential.accessToken;
        var userEmail = result.additionalUserInfo.profile.email;
        var user = result.user;
        var first = user.displayName.split(" ")[0];
        var last = user.displayName.split(" ")[1];
        console.log(
          "ffff",
          user,
          user.l,
          user.displayName,
          user.email,
          first,
          last
        );
        var data = {
          type: 1,
          email: userEmail,
          first_name: first,
          last_name: last,
          num: "000000",
          token: user.l,
          device_token: localStorage.getItem("dToken1"),
        };
        console.log("gggggggg", data);

        fetch(`${process.env.REACT_APP_API_END_POINT}/user/social_login.php`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            type: 1,
            email: userEmail,
            first_name: first,
            last_name: last,
            num: "000000",
            token: user.l,
            device_token: localStorage.getItem("dToken1"),
          }),
        })
          .then((res) => {
            console.log("res aqib", res);
            if (res.status !== 200) {
              alert("Email or password is incorrect");
            }
            return res.json();
          })
          .then((response) => {
            console.log("data", response);
            const auth_id = response.data.auth_id;

            if (auth_id !== "" && auth_id !== null && auth_id !== undefined) {
              window.location = "/dashboard";
            } else {
              alert("Email or password is incorrect");
            }
            localStorage.setItem("auth_id", auth_id);
            localStorage.setItem("first_name", first);
            // window.location.reload(false)
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
        console.log("ffff", errorMessage);
      });
  };
};
