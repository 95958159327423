export const createRegister = (
  first_name,
  last_name,
  email,
  number,
  code,
  password
) => {
  return (dispatch) => {
    dispatch({
      type: "RESET_REGISTER_MESSAGE",
      loading: false,
    });

    fetch(`${process.env.REACT_APP_API_END_POINT}/user/signup_new.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        secret_key: "a1f33e3555cae4dcb631d63ed534f1d1",
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        number: number,
        num_code: code,
        password: password,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        debugger;
        const auth_id = response.data.auth_id;
        const empId = response.data.id;
        const first_name = response.data.first_name;
        const is_verified = response.data.is_verified;
        if (response.status != 200) {
          alert(response.message);
          dispatch({
            loading: true,
          });
          return;
        } else if (
          auth_id !== "" &&
          auth_id !== null &&
          auth_id !== undefined &&
          response.status === 200
        ) {
          dispatch({
            type: "REGISTER_IN",
            authError: null,
            auth_id,
            employee_id: empId,
            authMessage: "Signing you in...",
            loading: true,
          });
          localStorage.setItem("auth_id", auth_id);
          localStorage.setItem("first_name", first_name);
          localStorage.setItem("isFirstLogin", 1);
          localStorage.setItem("is_verified", is_verified);
          window.location = "/otp";
          // window.location = "/personal?type=addProfile";
        }
      })
      .catch((error) => {
        const authError = JSON.stringify(error);
        dispatch({
          type: "REGISTER_IN",
          authError,
          auth_id: null,
          employee_id: null,
          authMessage: "Error occurred in signing in!",
          loading: true,
        });
      });
  };
};

export const signOut = (props) => {
  localStorage.removeItem("persist:root");
  window.location = "/";
  return {
    type: "SIGN_OUT",
  };
};
