export const createLinkedInData = (code) => {
    console.log(code)
    return (dispatch) => {
        dispatch({
            type: "LINKEDIN_RESET",
        });
        var data =
        {
            "code": code,
        }
            ;
        console.log(data)
        /// post request
        fetch(`${process.env.REACT_APP_API_END_POINT}/user/linkedin.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "code": code
            })
        }).then(res => {
            console.log("res aqib", res)
            if (res.status !== 200) {
                alert("Some thing went wrong...");
            }
            return res.json();
        }).then((response) => {
            console.log("pppppp", response);
            const sData = response.data;
            dispatch({
                type: "LINKEDIN_SUCCESS",
                linkedInResponse: response,
                linkedIn: sData,
            });
        
        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "LINKEDIN_FAIL",
                linkedInResponse: "creation failed",
                linkedIn: {}
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}
