import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
// import PhoneInput from 'react-phone-number-input'
import "react-phone-number-input/style.css";
import { countries } from "country-data";
import "../Login/Login.css";
import "../Register/Register.css";
import "../ForgetPassword/ForgetPassword.css";
import Logo from "../../Assests/navbar/logo.png";
import Google from "../../Assests/google-register.svg";
import Facebook from "../../Assests/facebook.svg";
import { createRegister } from "../../actions/registerAction";
import { createForgetPassword } from "../../actions/forgetPasswordAction";
import { connect } from "react-redux";
import { SignIn, facebookLogin, googleLogin } from "../../actions/authAction";
import LinkedInPage2 from "../../getURL";
import FullPageLoader from "../fullpageloader/fullPageLoader";
import {
  LoginModalAction,
  RegisterModalAction,
} from "../../actions/loginModalAction";
import { Dropdown } from "primereact/dropdown";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
Modal.setAppElement("#root");

function Navbar(props) {
  // const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [LoginmodalIsOpen, setLoginModalIsOpen] = useState(false);
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const [forgetModalIsOpen, setForgetModalIsOpen] = useState(false);
  const [defaultCountryCode, setdefaultCountryCode] = useState("");
  const [username, setusername] = useState("");
  const [passwordlog, setpasswordlog] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confrimpassword, setConfirmPassword] = useState("");
  const [termsCheck, setTermsCheck] = useState("");
  const [ForgetEmail, setForgetEmail] = useState("");
  const [countriesCode, setCountriesCode] = useState([]);

  const addNewRegister = (e) => {
    if (number.toString().length < 7) {
      alert("Mobile Number is Invalid");
      return;
    }
    const reg =
      /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.([a-zA-Z]+).([a-zA-Z]+)$/;
    if (first_name == "") {
      alert("Enter first name");
    } else if (last_name == "") {
      alert("Enter last name");
    } else if (email == "") {
      alert("Email required");
    } else if (reg.test(email) === false) {
      alert("Email is invalid");
    } else if (number == "") {
      alert("Cellphone number is required");
    } else if (password == "") {
      alert("Password required");
    } else if (password.length < 6) {
      alert("Password should be at least 6 characters");
    } else if (confrimpassword == "") {
      alert("Confirm password is required");
    } else if (confrimpassword !== password) {
      alert("Passwords didn't match");
    } else if (!termsCheck) {
      alert("Kindly agree to terms and conditions");
    } else {
      props.createRegister(
        first_name,
        last_name,
        email,
        number,
        code,
        password
      );
    }
  };

  const newForget = async () => {
    const reg =
      /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.([a-zA-Z]+).([a-zA-Z]+)$/;
    if (ForgetEmail == "") {
      alert("Email required");
    } else if (reg.test(ForgetEmail) === false) {
      alert("Email is invalid");
    } else {
      await props.createForgetPassword(ForgetEmail);
    }
  };
  useEffect(async () => {
    let defaultCountryCode = countries.all.find(
      (x) => x.name == localStorage.getItem("userCurrentLocation")
    );
    setdefaultCountryCode(defaultCountryCode);
    setCode(defaultCountryCode?.countryCallingCodes?.[0]);

    let codes = [];
    countries.all.forEach((x) => {
      if (x.countryCallingCodes[0]) {
        let object = {
          name: x.name + " " + x.countryCallingCodes[0],
          value: x.countryCallingCodes[0],
        };
        codes.push(object);
      }
    });
    setCountriesCode((prev) => (prev = codes));
  }, []);
  const ForgetFunctionality = () => {
    setForgetModalIsOpen(true);
    props.LoginModalAction(false);
  };
  const ForgetFunctionality2 = () => {
    props.LoginModalAction(true);
    setForgetModalIsOpen(false);
  };

  const registermodal = () => {
    props.LoginModalAction(true);
    props.RegisterModalAction(false);
  };

  const registermodal2 = () => {
    props.RegisterModalAction(true);
    props.LoginModalAction(false);
  };

  const handleName = (e) => {
    setFirst_name(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleLastName = (e) => {
    setLast_name(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // const handlePhone = (e) => {
  //   setNumber(e.target.value);
  // };

  const onSubmitLogin = async () => {
    const reg =
      /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.([a-zA-Z]+).([a-zA-Z]+)$/;
    if (username == "") {
      alert("Email required");
    } else if (passwordlog == "") {
      alert("password required");
    } else if (reg.test(username) === false) {
      alert("Email is invalid");
    } else if (passwordlog.length < 6) {
      alert("Password should be at least 6 characters");
    } else {
      await props.SignIn(username, passwordlog);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow p-0 m-0">
        <div className="container px-3">
          <Link className="navbar-brand" to="/home">
            <img src={Logo} className="p-0 m-0" height="50px" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              <li className="nav-item mx-1 ">
                <NavLink
                  exact
                  className="nav-link active"
                  activeClassName="something-new-cand"
                  style={{ fontSize: "12px", color: "#707070" }}
                  to="/home"
                >
                  HOME
                </NavLink>
              </li>
              <li className="nav-item mx-1 ">
                <NavLink
                  exact
                  className="nav-link active"
                  activeClassName="something-new-cand"
                  style={{ fontSize: "12px", color: "#707070" }}
                  to="/jobs"
                >
                  JOBS
                </NavLink>
              </li>
              <li className="nav-item mx-1 ">
                <NavLink
                  exact
                  className="nav-link active"
                  activeClassName="something-new-cand"
                  style={{ fontSize: "12px", color: "#707070" }}
                  to="/whatwedo"
                >
                  HOW IT WORKS
                </NavLink>
              </li>
              <li className="nav-item mx-1">
                <NavLink
                  exact
                  className="nav-link active"
                  activeClassName="something-new-cand"
                  to="/about-us"
                  style={{ fontSize: "12px", color: "#707070" }}
                >
                  ABOUT US
                </NavLink>
              </li>
              <li
                className="nav-item mx-1"
                onClick={() => props.LoginModalAction(true)}
              >
                <Link
                  className="nav-link active text_gray text-of-nav-cand"
                  aria-current="page"
                  activeClassName="active"
                >
                  MESSAGES
                </Link>
              </li>
              <li
                className="nav-item mx-1"
                onClick={() => props.LoginModalAction(true)}
              >
                <Link
                  className="nav-link active text_gray text-of-nav-cand"
                  aria-current="page"
                  activeClassName="active"
                  exact
                >
                  LOGIN
                </Link>
              </li>
              {/* Login Modal Start */}
              <Modal
                isOpen={props.loginModalReducer.loginModal}
                onRequestClose={() => props.LoginModalAction(false)}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.4)",
                    zIndex: "1",
                  },
                  content: {
                    position: "absolute",
                    margin: "0 auto",
                    width: "350px",
                    height: "467px",
                    top: "100px",
                    left: "0",
                    right: "0",
                    bottom: "100px",
                    border: "1px solid #ccc",
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "20px",
                    boxShadow: "0 0 5px 5px #f2f2f2",
                    borderRadius: "20px",
                    background: "#fff",
                    border: "1px solid #fff",
                  },
                }}
              >
                {/* Heading Starts */}
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                    <h1 className="login-head">Login</h1>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                {/* Heading Starts */}

                {/* Input Starts */}
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="label-color-login">
                      Username or Email
                    </label>
                    <input
                      onChange={(e) => setusername(e.target.value)}
                      type="email"
                      className="form-control m-0 p-0"
                      placeholder="Type your username or email"
                      id="login-inp"
                      style={{ color: "#000" }}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label className="label-color-login">Password</label>
                    <input
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onSubmitLogin();
                        }
                      }}
                      onChange={(e) => setpasswordlog(e.target.value)}
                      type="password"
                      className="form-control m-0 p-0"
                      placeholder="Type your password"
                      id="login-inp"
                      style={{ color: "#000" }}
                    />
                  </div>
                </div>
                <div className="row mt-1 mb-3">
                  <div className="col-md-12 d-flex justify-content-end">
                    <small
                      className="forget-pass-login"
                      onClick={ForgetFunctionality}
                      style={{ cursor: "pointer" }}
                    >
                      {/* ---------------------------------------- */}
                      Forget Password?
                    </small>
                  </div>
                </div>
                {/* Input Ends */}

                {/* Button Starts */}
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10 d-flex justify-content-center">
                    {/* <Link to="dashboard" className="w-100"> */}
                    <button
                      className="btn btn-warning w-100 btn-login"
                      onClick={() => onSubmitLogin()}
                      // disabled={
                      //   !username === false && !passwordlog === false
                      //     ? false
                      //     : true
                      // }
                    >
                      Log in
                    </button>
                    {/* </Link> */}
                  </div>
                  <div className="col-md-1"></div>
                </div>
                {/* Button Ends */}

                {/* Horizontal Rule Starts */}
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <hr />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                {/* Horizontal Rule Ends */}

                {/* Icons Starts */}
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 m-0 p-0 d-flex justify-content-center">
                    <button
                      className="m-0 p-0 icon-reg-btn"
                      onClick={() => props.googleLogin()}
                    >
                      <img src={Google} alt="" />
                    </button>
                    <button
                      onClick={() => props.facebookLogin()}
                      className="m-0 p-0 icon-reg-btn"
                    >
                      <img src={Facebook} alt="" />
                    </button>

                    <button className="m-0 p-0 icon-reg-btn">
                      <LinkedInPage2 />
                      {/* <img src={LinkedIn} alt="" /> */}
                    </button>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                {/* Icons Ends */}

                {/* Footer Starts */}
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    <p className="footer-reg-color">
                      Don't have an account?
                      <span
                        className="login-foot-reg"
                        onClick={() => registermodal2()}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        Register here
                      </span>
                    </p>
                  </div>

                  {props.loginModalReducer.loaded != true ? (
                      <></>
                  ) : (
                      <FullPageLoader />
                  )}
                  {/*{props.auth.loading == true ? <></> : <FullPageLoader />}*/}
                </div>
                {/* Footer Ends */}
              </Modal>
              {/* Login Modal End */}
              <li
                className="nav-item"
                onClick={() => props.RegisterModalAction(true)}
              >
                <a
                  className="nav-link active text_white py-1 px-2 mr-2 mt-1 color-of-reg text-of-nav-cand btn-of-main-div-cand"
                  aria-current="page"
                  href="#"
                  style={{ borderRadius: "7px" }}
                >
                  <i className="far fa-user pr-1"></i>REGISTER
                </a>
              </li>
              {/* Register Modal Starts */}
              <Modal
                isOpen={props.loginModalReducer.registerModal}
                onRequestClose={() => props.RegisterModalAction(false)}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.4)",
                    zIndex: "1",
                  },
                  content: {
                    position: "absolute",
                    margin: "0 auto",
                    width: "700px",
                    height: "550px",
                    top: "50px",
                    left: "0",
                    right: "0",
                    bottom: "50px",
                    border: "1px solid #ccc",
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "20px",
                    boxShadow: "0 0 5px 5px #f2f2f2",
                    borderRadius: "20px",
                    background: "#fff",
                    border: "1px solid #fff",
                  },
                }}
              >
                {/* Heading Starts */}
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <h1 className="register-head">Register</h1>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                {/* Heading Starts */}

                {/* FIrst Input Starts */}
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label-color-reg mt-3">First Name</label>
                      <input
                        onChange={handleName}
                        value={first_name}
                        type="text"
                        placeholder="Type your first name here"
                        className="form-control per-det-input"
                        id="reg-form-all"
                      />
                    </div>
                    {/* Last name Starts */}
                    <div className="col-md-6">
                      <label className="label-color-reg mt-3 ">Last Name</label>
                      <input
                        onChange={handleLastName}
                        value={last_name}
                        type="text"
                        placeholder="Type your last name here"
                        className="form-control per-det-input"
                        id="reg-form-all"
                      />
                    </div>
                    {/* Last name ends */}
                  </div>
                  {/* FIrst Input Ends */}

                  {/* Second Input Starts */}
                  <div className="row">
                    {/* Email Starts */}
                    <div className="col-md-6">
                      <label className="label-color-reg mt-3">Email</label>
                      <input
                        onChange={handleEmail}
                        // required
                        value={email}
                        type="email"
                        placeholder="Type your email here"
                        className="form-control per-det-input"
                        id="reg-form-all"
                      />
                    </div>
                    {/* Email Ends */}
                    {/* Phone Number Starts */}
                    <div className="col-md-6">
                      <label className="label-color-reg mt-3">
                        Phone Number
                      </label>
                      <div className="row">
                        <div className="col-12 d-flex pr-0">
                          {/* <select
                            className=" per-det-input  pl-2"
                            id="reg-form-all"
                            onChange={(e) => setCode(e.target.value)}
                          >
                            {localStorage.getItem("userCurrentLocation") ? (
                              <option
                                value={
                                  defaultCountryCode?.countryCallingCodes?.[0]
                                }
                                select
                              >
                                {defaultCountryCode?.countryCallingCodes?.[0]}
                              </option>
                            ) : null}
                            {countries.all.map((exp) =>
                              exp.countryCallingCodes.length ? (
                                <option value={exp.countryCallingCodes[0]}>
                                  {exp.countryCallingCodes[0]}
                                </option>
                              ) : null
                            )}
                          </select> */}
                          {/* <Dropdown
                            value={code}
                            options={countriesCode}
                            onChange={(e) => setCode(e.target.value)}
                            optionLabel="name"
                            filter
                            placeholder="Select a Country Code"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              borderBottom: "1px solid black",
                            }}
                          /> */}
                          <IntlTelInput
                            preferredCountries={["pk"]}
                            inputClassName="form-control per-det-input register"
                            onPhoneNumberChange={(
                              status,
                              phoneNumber,
                              country
                            ) => {
                              setCode("+" + country.dialCode);
                              setNumber(phoneNumber);
                            }}
                          />
                        </div>
                        {/* <div className="d-flex col-8">
                          <input
                            onChange={(e) => setNumber(e.target.value)}
                            type="text"
                            placeholder="Type your phone number"
                            className="form-control pl-2"
                            id="reg-form-all"
                            className="form-control per-det-input"
                          />
                        </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                    {/* Phone Number ends */}
                  </div>
                  {/* Second Input Ends */}

                  {/* Third Input Starts */}
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label-color-reg mt-3 ">Password</label>
                      <input
                        onChange={handlePassword}
                        value={password}
                        type="password"
                        placeholder="Type your password here"
                        className="form-control per-det-input"
                        id="reg-form-all"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="label-color-reg mt-3">
                        Confirm Password
                      </label>
                      <input
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        value={confrimpassword}
                        placeholder="Type your password again"
                        className="form-control per-det-input"
                        id="reg-form-all"
                      />
                    </div>
                  </div>
                  {/* Third Input Ends */}

                  {/* Terms & Cond Text Starts */}
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 mt-3">
                      <p className="reg-para d-flex">
                        <input
                          className="mt-1"
                          defaultChecked={termsCheck}
                          onChange={() => setTermsCheck(!termsCheck)}
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              addNewRegister();
                            }
                          }}
                        />
                        <label for="vehicle1">
                          {" "}
                          By clicking "Register" you agree to our{" "}
                          <Link
                            to="terms-and-condition"
                            onClick={() => props.RegisterModalAction(false)}
                          >
                            <span className="term-n-cond">
                              Terms {`&`} Conditions
                            </span>
                          </Link>{" "}
                          as well as our{" "}
                          <Link
                            to="privacy-policy"
                            onClick={() => props.RegisterModalAction(false)}
                          >
                            <span className="term-n-cond">Privacy Policy</span>
                          </Link>
                        </label>
                      </p>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  {/* Terms & Cond Text Ends */}

                  {/* Register Btn Starts */}
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <button
                        onClick={(e) => addNewRegister(e)}
                        // disabled={
                        //   !first_name === false &&
                        //   !last_name === false &&
                        //   !email === false &&
                        //   !number === false &&
                        //   !password === false &&
                        //   !confrimpassword === false &&
                        //   !termsCheck === false
                        //     ? false
                        //     : true
                        // }
                        className="btn btn-warning reg-btn-main w-100"
                      >
                        Register
                      </button>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </form>
                {/* Register Btn Ends */}

                {/* Or Starts */}
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                    <hr className="ruler-reg" />
                  </div>
                  <div className="col-md-2"></div>
                </div>
                {/* Or Ends */}

                {/* Footer Starts */}
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 d-flex justify-content-center">
                    <p className="footer-reg-color">
                      Already have an account?
                      <span
                        className="login-foot-reg"
                        onClick={() => registermodal()}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        Login here
                      </span>
                    </p>
                  </div>
                  <div className="col-md-3"></div>
                  {props.registerReducer.loading == true ? (
                    <></>
                  ) : (
                    <FullPageLoader />
                  )}
                </div>
                {/* Footer Ends */}
              </Modal>
              {/* Register Modal Ends */}
              {/*<li className="nav-item mx-1">
                <Link
                  className="nav-link active text_white py-1 px-2 mt-1 bg_orange text-of-nav-cand btn-of-main-div-cand"
                  aria-current="page"
                  to={"Premium"}
                  style={{ fontSize: "12px" }}
                >
                  <i className="far fa-gem pr-1"></i>PACKAGES
                </Link>
              </li>*/}
              <li className="nav-item mx-1">
                <a
                  className="nav-link active"
                  activeClassName="something-new-cand"
                  style={{ fontSize: "12px", color: "#707070" }}
                  href="https://recruiter.cvvlogs.com/"
                  target="_blank"
                >
                  FOR RECRUITERS <br />
                  <span style={{ fontSize: "10px" }}>
                    (A recruiter can login or register here)
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Forget Password Modal Starts */}
        <Modal
          isOpen={forgetModalIsOpen}
          onRequestClose={() => setForgetModalIsOpen(false)}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.4)",
              zIndex: "1",
            },
            content: {
              position: "absolute",
              margin: "0 auto",
              width: "350px",
              height: "280px",
              top: "190px",
              left: "0",
              right: "0",
              bottom: "190px",
              border: "1px solid #ccc",
              background: "#fff",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              boxShadow: "0 0 5px 5px #f2f2f2",
              borderRadius: "20px",
              background: "#fff",
              border: "1px solid #fff",
            },
          }}
        >
          {/* Heading Starts */}
          <div className="row">
            <div className="col-md-12">
              <h1 className="forget-head">Forgot your password?</h1>
            </div>
          </div>
          {/* Heading Starts */}

          {/* Para Starts */}
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <p className="para-forget">
                Enter your email address below and we will send you a reset
                password link.
              </p>
            </div>
            <div className="col-md-1"></div>
          </div>
          {/* Para Ends */}

          {/* input Starts */}
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-md-12">
                <label className="forget-label">
                  Enter Registered Email ID
                </label>
                <input
                  type="email"
                  // required
                  className="form-control"
                  placeholder="Enter your Email ID"
                  id="forget-input"
                  value={ForgetEmail}
                  onChange={(e) => setForgetEmail(e.target.value)}
                  style={{ color: "#000" }}
                />
              </div>
            </div>
            {/* input Ends */}

            {/* Forget Pass Div Starts*/}
            <div className="row mt-1">
              <div className="col-md-12 d-flex justify-content-end">
                <small
                  className="login-instead"
                  onClick={() => ForgetFunctionality2()}
                  style={{ cursor: "pointer" }}
                >
                  Remember your password? Login instead
                </small>
              </div>
            </div>
            {/* Forget Pass Div Ends*/}

            {/* Forget Pass Div Starts*/}
            <div className="row mt-3">
              <div className="col-md-12 d-flex justify-content-center">
                <input
                  type="submit"
                  value="submit"
                  className="btn btn-warning btn-forget w-50"
                  onClick={() => newForget()}
                  // disabled={!ForgetEmail === false ? false : true}
                />
              </div>
            </div>
          </form>
          {/* Forget Pass Div Ends*/}
        </Modal>
        {/* Forget Password Modal Ends */}
        {/* Feedback Modal  */}
      </nav>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  registerReducer: state.registerReducer,
  loginModalReducer: state.loginModalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  SignIn: (username, passwordlog) => dispatch(SignIn(username, passwordlog)),

  createRegister: (first_name, last_name, email, number, code, password) =>
    dispatch(
      createRegister(first_name, last_name, email, number, code, password)
    ),

  createForgetPassword: (email) => dispatch(createForgetPassword(email)),

  facebookLogin: () => dispatch(facebookLogin()),
  googleLogin: () => dispatch(googleLogin()),
  LoginModalAction: (toggle) => dispatch(LoginModalAction(toggle)),
  RegisterModalAction: (toggle) => dispatch(RegisterModalAction(toggle)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
