const initState = {
    selectPdf: {},
    selectPdfResponse: null,

}

const selectPdfReducer = (state = initState, action) => {
    if (action.type === 'GET_SELECTPDF') {
        return {
            ...state,
            selectPdf: action.selectPdf,
            selectPdfResponse:action.selectPdfResponse
        }
    }
    // /// THESE ARE GENERAL APPLICABLE TO ALL API's
    else if (action.type === 'SELECTPDF_RESET') {
        return {
            ...state,
            selectPdfResponse: null,

        }
    }
    else if (action.type === 'SELECTPDF_SUCCESS') {
        return {
            ...state,
            selectPdfResponse: action.selectPdfResponse,
        }
    }
    else if (action.type === 'SELECTPDF_FAIL') {
        return {
            ...state,
            selectPdfResponse: action.selectPdfResponse,
        }
    }

    return state;
}
export default selectPdfReducer;
