const config = require("../helpers/config.json");
export const getDashboard = (userId) => {
  return (dispatch) => {
    /// get request
    fetch(`${process.env.REACT_APP_API_END_POINT}/user/web_home.php`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({
      //     "data":{
      //         "company_url": companyUrl,
      //         "email": email,
      //         "password": password
      //     }
      // })
    })
      .then((res) => res.json())
      .then((response) => {
        const dashboard = response.data;

        dispatch({
          type: "GET_DASHBOARD",
          dashboard: dashboard,
          dashboardResponse: "got it",
        });
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: "GET_PERSONAL",
          dashboard: {},
          dashboardResponse: null,
        });
        // alert("Please Check Your Internet Connection...")
      });
  };
};

export const getAllJobsDashboard = (userId) => {
  return (dispatch) => {
    /// get request
    fetch(
      `${process.env.REACT_APP_API_END_POINT}/user/get_job_exclude_ind.php`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json", auth_id: userId },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        const dashboard = response.data.job;

        dispatch({
          type: "GET_DASHBOARD_ALL_JOBS",
          dashboardAllJobs: dashboard,
          dashboardResponse: "got it",
        });
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: "GET_PERSONAL",
          dashboard: {},
          dashboardResponse: null,
        });
        // alert("Please Check Your Internet Connection...")
      });
  };
};
