import React, { useState, useEffect } from "react";
import Linked from "../src/Assests/linked-in-register.svg";
import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import "./index.css";
import { createLinkedInData } from "./actions/LinkedInDataAction";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

function LinkedInPage2() {
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const search = useLocation().search;

  const handleSuccess = (data) => {
    fetch(`${process.env.REACT_APP_API_END_POINT}/user/linkedin.php`, {
      method: "POST",
      body: JSON.stringify({
        code: data.code,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        var email = response.data.email;
        if (response.status == 200) {
          var data = {
            type: 3,
            email: email,
            first_name: "first",
            last_name: "last",
            num: "000000",
            token: code,
            device_token: localStorage.getItem("dToken1"),
          };

          fetch(
            `${process.env.REACT_APP_API_END_POINT}/user/social_login.php`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                type: 3,
                email: email,
                first_name: "null",
                last_name: "null",
                num: "000000",
                token: data.code,
                device_token: localStorage.getItem("dToken1"),
              }),
            }
          )
            .then((res) => {
              if (res.status !== 200) {
                alert("Incorrect Email Or Password 200...");
              }
              return res.json();
            })
            .then((response) => {
              const auth_id = response.data.auth_id;

              if (auth_id !== "" && auth_id !== null && auth_id !== undefined) {
                window.location = "/personaldetails";
              } else {
                alert("email or password is incorrect...");
              }
              localStorage.setItem("auth_id", auth_id);
              // localStorage.setItem("first_name", first)
              // window.location.reload(false)
            })
            .catch((error) => {
              console.log("finnnn", error);
            });
        }
      })
      .catch((error) => {
        console.log("linnnn error!!!! ", error);
      });
  };

  const handleFailure = (error) => {
    setMessage(error.errorMessage);
  };
  return (
    <div>
      <LinkedIn
        clientId="77ul9euvdop3a4"
        // callback={callbackLinkedIn}
        onFailure={handleFailure}
        onSuccess={handleSuccess}
        redirectUri="https://cvvlogs.com/linkedin"
      >
        <img
          src={Linked}
          alt="Log in with Linked In"
          style={{ maxWidth: "180px" }}
        />
      </LinkedIn>
    </div>
  );
}

// https://cvvlogs.com/cv-tube/auth.php

const mapStateToProps = (state) => ({
  auth: state.auth,
  LinkedInReducer: state.LinkedInReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // getProfessional: () => dispatch(getProfessional()),
  createLinkedInData: (userId) => dispatch(createLinkedInData(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LinkedInPage2);
