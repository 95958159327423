import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./Nav2.css";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../Assests/navbar/logo.png";
import { signOut } from "../../actions/authAction";
import { getDashboardLogin } from "../../actions/dashboardloginAction";
import { connect } from "react-redux";
import DisableOtp from "../Otp/DisableOtp";
import DeleteOtp from "../Otp/DeleteOtp";
import { propTypes } from "react-bootstrap/esm/Image";
import axios from "axios";
import { FeedbackModalAction } from "../../actions/feedbackModalAction";
import { feedBack } from "../../actions/feedbackAction";

Modal.setAppElement("#root");

function Nav2(props) {
    const [LoginmodalIsOpen, setLoginModalIsOpen] = useState(false);
    const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
    const [forgetModalIsOpen, setForgetModalIsOpen] = useState(false);
    const [userName, setUserName] = useState("");
    const [feedback, setFeedback] = useState("");

    const ForgetFunctionality = () => {
        setForgetModalIsOpen(true);
        setLoginModalIsOpen(false);
    };

    useEffect(() => {
        loadGetPersonal(localStorage.getItem("auth_id"));
    }, []);
    useEffect(() => {
        if (localStorage.getItem("isFirstLogin") == 1) {
            props.FeedbackModalAction(true);
        }
    }, []);

    const OTP = () => {
        fetch(`${process.env.REACT_APP_API_END_POINT}/user/disable_otp.php`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                auth_id: localStorage.getItem("auth_id"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    window.location = "otp-disable";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const DeleteAccount = () => {
        fetch(
            `${process.env.REACT_APP_API_END_POINT}/user/send_delete_account_otp.php`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    auth_id: localStorage.getItem("auth_id"),
                },
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    window.location = "otp-delete";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadGetPersonal = async (userId) => {
        await props.getDashboardLogin(userId);
        return null;
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow">
            <div className="container">
                <Link to="/dashboard">
                    <img src={Logo} height="50px" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                        <li className="nav-item mx-1">
                            <NavLink
                                exact
                                className="nav-link active"
                                activeClassName="something-new-cand"
                                to="/dashboard"
                                style={{ fontSize: "12px", color: "#707070" }}
                            >
                                HOME
                            </NavLink>
                        </li>
                        <li className="nav-item mx-1">
                            <NavLink
                                exact
                                className="nav-link active"
                                activeClassName="something-new-cand"
                                to="/jobs-login"
                                style={{ fontSize: "12px", color: "#707070" }}
                            >
                                JOBS
                            </NavLink>
                        </li>
                        <li className="nav-item mx-1 ">
                            <NavLink
                                exact
                                className="nav-link active"
                                activeClassName="something-new-cand"
                                to="/whatwedologin"
                                style={{ fontSize: "12px", color: "#707070" }}
                            >
                                HOW IT WORKS
                            </NavLink>
                        </li>
                        <li className="nav-item mx-1 ">
                            <NavLink
                                exact
                                className="nav-link active"
                                activeClassName="something-new-cand"
                                to="/about-us"
                                style={{ fontSize: "12px", color: "#707070" }}
                            >
                                ABOUT US
                            </NavLink>
                        </li>
                        <li className="nav-item mx-1">
                            <NavLink
                                exact
                                className="nav-link active"
                                activeClassName="something-new-cand"
                                to="/message"
                                style={{ fontSize: "12px", color: "#707070" }}
                            >
                                MESSAGES
                            </NavLink>
                        </li>

                        {/*Upload CV Dropdown*/}
                        <li className="nav-item mx-1">
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className="btn dropdown-toggle p-0 py-1 px-2 mt-1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    id="btn-nav-user"
                                    style={{ fontSize: "10px", borderRadius: "7px", background: "#FF0000" }}
                                >
                                    Upload CV
                                </button>
                                <ul className="dropdown-menu">
                                    <li style={{
                                        fontSize: "12px",
                                        color: "#707070",
                                        cursor: "pointer",
                                    }}>
                                        <Link className="dropdown-item"
                                            aria-current="page"
                                            to="/save-cv"
                                        >
                                            Record CV
                                        </Link>
                                    </li>
                                    <li style={{
                                        fontSize: "12px",
                                        color: "#707070",
                                        cursor: "pointer",
                                    }}>
                                        <Link className="dropdown-item"
                                            aria-current="page"
                                            to="/save-cv-pdf"
                                        >
                                            Upload PDF
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        {/*Upload CV Dropdown ENDS Here*/}
                        <li className="nav-item mx-2">
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className="btn dropdown-toggle p-0 py-1 px-2 mt-1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    id="btn-nav-user"
                                    style={{ fontSize: "10px", borderRadius: "7px" }}
                                >
                                    <i className="far fa-user pr-1"></i>{" "}
                                    {props.dashboardLoginReducer.dashboardlogin.profile
                                        ? props.dashboardLoginReducer.dashboardlogin.profile
                                            .name !== null &&
                                            props.dashboardLoginReducer.dashboardlogin.profile
                                                .name !== undefined
                                            ? props.dashboardLoginReducer.dashboardlogin.profile.name
                                                .split(" ")[0]
                                                .toUpperCase()
                                            : ""
                                        : ""}
                                </button>
                                <ul className="dropdown-menu">
                                    <li
                                        style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Link className="dropdown-item" to="/profile">
                                            View{` & `}Edit Profile
                                        </Link>
                                    </li>
                                    <li
                                        style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <a className="dropdown-item" onClick={() => OTP()}>
                                            Disable Account
                                        </a>
                                    </li>
                                    <li
                                        style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <a
                                            className="dropdown-item"
                                            onClick={() => DeleteAccount()}
                                        >
                                            Delete Account
                                        </a>
                                    </li>
                                    <li
                                        style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <a className="dropdown-item" onClick={() => signOut()}>
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        {/* <li className="nav-item mx-1">
                            <Link
                                className="nav-link active text_white py-1 px-2 mt-1 bg_orange text-of-nav-cand btn-of-main-div-cand"
                                aria-current="page"
                                to={"Premium"}
                                style={{fontSize: "12px"}}
                            >
                                <i className="far fa-gem pr-1"></i>PACKAGES
                            </Link>
                        </li> */}

                        <li className="nav-item mx-1">
                            <a
                                className="nav-link active"
                                activeClassName="something-new-cand"
                                href="https://recruiter.cvvlogs.com/"
                                target="_blank"
                                style={{ fontSize: "12px", color: "#707070" }}
                            >
                                FOR RECRUITERS <br />
                                <span style={{ fontSize: "10px" }}>
                                    (A recruiter can login or register here)
                                </span>
                            </a>
                        </li>
                    </ul>
                    <Modal
                        isOpen={props.feedbackModalReducer.feedbackModal}
                        onRequestClose={() => {
                            props.FeedbackModalAction(false);
                        }}
                        style={{
                            overlay: {
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(0,0,0,0.4)",
                                zIndex: "1",
                            },
                            content: {
                                position: "absolute",
                                margin: "0 auto",
                                width: "500px",
                                height: "500px",
                                top: "40px",
                                left: "0",
                                border: "1px solid #ccc",
                                background: "#fff",
                                borderRadius: "4px",
                                outline: "none",
                                padding: "20px",
                                boxShadow: "0 0 5px 5px #f2f2f2",
                                borderRadius: "20px",
                                background: "#fff",
                                border: "1px solid #fff",
                            },
                        }}
                    >
                        {/* Heading Starts */}
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="forget-head">Feedback</h1>
                                <p>
                                    Thank you for choosing our services . It's our priority to
                                    continue providing quality service to customers like you .
                                    Please leave us a review in the comment box below . It will
                                    only take a minute but will make a huge difference to our
                                    company.
                                </p>
                            </div>
                        </div>
                        {/* Heading Starts */}

                        {/* input Starts */}
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea
                                        placeholder="Enter Your feedback"
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                        cols="30"
                                        rows="10"
                                        style={{ color: "#000" }}
                                        className="form-control"
                                    ></textarea>
                                </div>
                            </div>
                            {/* input Ends */}

                            {/* Forget Pass Div Starts*/}
                            <div className="row mt-3">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <input
                                        type="submit"
                                        value="Submit"
                                        className="btn btn-warning btn-forget w-50"
                                        onClick={() =>
                                            props.feedBack(localStorage.getItem("auth_id"), feedback)
                                        }
                                        disabled={!feedback === false ? false : true}
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            </div>
        </nav>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    dashboardLoginReducer: state.dashboardLoginReducer,
    feedbackModalReducer: state.feedbackModalReducer,
});

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut()),
    getDashboardLogin: (userId) => dispatch(getDashboardLogin(userId)),
    FeedbackModalAction: (toggle) => dispatch(FeedbackModalAction(toggle)),
    feedBack: (userId, feedback) =>
        dispatch(feedBack(userId, feedback, "pop up")),
});
export default connect(mapStateToProps, mapDispatchToProps)(Nav2);
