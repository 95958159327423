const initState = {
  loginModal: false,
  registerModal: false,
};

const loginModalReducer = (state = initState, action) => {
  if (action.type === "LOGIN_MODAL_TOGGLE") {
    return {
      ...state,
      loginModal: action.loginModal,
    };
  }
  if (action.type === "REGISTER_MODAL_TOGGLE") {
    return {
      ...state,
      registerModal: action.registerModal,
    };
  }
  return state;
};
export default loginModalReducer;
