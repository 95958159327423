// // import  firebase from "firebase/app"
// // import "firebase/auth"
// import firebase from 'firebase'
// require('firebase/auth')


// var firebaseConfig = {
//     apiKey: "AIzaSyA7M7RghcvHvFSzsXhpmz73XCzOYzs50rk",
//     authDomain: "cv-tube-web-candidiate.firebaseapp.com",
//     projectId: "cv-tube-web-candidiate",
//     storageBucket: "cv-tube-web-candidiate.appspot.com",
//     messagingSenderId: "3621460259",
//     appId: "1:3621460259:web:8aeafde1da8b8bdc38d496",
//     measurementId: "G-M88881C11Z"
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
//   // firebase.analytics();
  
//   export default firebase






















// import  firebase from "firebase/app"
// import "firebase/auth"
import firebase from 'firebase'
require('firebase/auth')


// var firebaseConfig = {
//      apiKey: "AIzaSyB5IcDHgnDncyrkUXab7rT9eZnuryaX8NI",
//     authDomain: "the-cv-tube-a0174.firebaseapp.com",
//     databaseURL: "https://the-cv-tube-a0174-default-rtdb.firebaseio.com",
//     projectId: "the-cv-tube-a0174",
//     storageBucket: "the-cv-tube-a0174.appspot.com",
//     messagingSenderId: "56503410117",
//     appId: "1:56503410117:web:5211b6a5def408843a7fac",
//     measurementId: "G-4PQGQV2F17"
//   };
const firebaseConfig = {
  apiKey: "AIzaSyBrxskkw2LYfMQZMYX8P0R2wbURHAhTxUc",
  authDomain: "cvvlogs.firebaseapp.com",
  databaseURL: "https://cvvlogs-default-rtdb.firebaseio.com",
  projectId: "cvvlogs",
  storageBucket: "cvvlogs.appspot.com",
  messagingSenderId: "994514516375",
  appId: "1:994514516375:web:e866458c36f433cd73ecd2",
  measurementId: "G-3NZ2FSYDPQ"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // firebase.analytics();
  
  export default firebase